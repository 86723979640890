import { TransactionReceipt } from "@ethersproject/abstract-provider"
import { addBreadcrumb, captureException, Severity } from "@sentry/nextjs"
import { useWeb3React } from "@web3-react/core"
import { poll } from "ethers/lib/utils"
import { useContext } from "react"
import { isMobile } from "react-device-detect"
import { ContractType, getPolygonProvider } from "web/lib/contract"
import { getSignature, getTypedDataForCommand, TypedData } from "web/lib/forwarder"
import { NonceContext } from "web/lib/nonce-provider"
import useEnvironment from "./useEnvironment"
import usePolygonToast from "./usePolygonToast"

export default function usePolygonForwarder() {
  const { account, library } = useWeb3React()
  const polygonProvider = getPolygonProvider()
  const { log } = useEnvironment()

  const { showToast, completeToast, failToast } = usePolygonToast()

  const [nonceManager, dispatchNonce] = useContext(NonceContext)

  const polygonForwarderSubmit = async (contractType: ContractType, command: string, args: any[] = []): Promise<GawdTransactionReceipt | TransactionError> => {
    if (nonceManager.isFetching) {
      console.warn("Waiting for previous txn to go through... ")
      return
    }

    const promise = new Promise<GawdTransactionReceipt | TransactionError>(async (resolve, reject) => {

      // Set global fetching state to prevent future actions (mobile only)
      if (isMobile) {
        dispatchNonce(['update', { isFetching: true }])
      }

      addBreadcrumb({
        category: 'gawchi',
        message: `${contractType} cmd:${command} args:${args}`,
        level: Severity.Debug
      })

      log('polygonForwarderSubmit Started...', { contractType, command, args, account })

      try {
        const data = await getTypedDataForCommand(contractType, command, args, account)
        const signature = await getSignature(data, account, library)

        log(`polygonForwarderSubmit.sendMetaTransaction contactName=${contractType} command=${command} data =>`, data)
        const receipt = await sendMetaTransaction(command, signature, data)

        resolve(receipt)
      }
      catch (e: any) {
        let errmsg = null

        if (e?.code && e.code === 4001) {
          errmsg = "User rejected signing"
        }
        else if (e instanceof Error) {
          const gawchiMatch = e.message.match(/(execution reverted:.+?)\\/)
          if (gawchiMatch) {
            // Contract error message
            errmsg = gawchiMatch[1]
          }
          else if (e.message.match(/transaction failed/)) {
            errmsg = "Transaction failed"
            captureException(new Error(errmsg, { cause: e }))
          }
        }

        // If not a handled error, then sentry capture it
        if (!errmsg) {
          captureException(e)
        }

        const error = errmsg ?  Error(errmsg, { cause: e }) : e

        console.error(error)

        dispatchNonce(['update', { isFetching: false }])

        reject(error)
      }
    })

    return promise
  }

  // send user-signed txn to our server-side API for wallet-signing and execution
  async function sendMetaTransaction(command: string, signature: string, data: TypedData): Promise<GawdTransactionReceipt | TransactionError> {
    const metaTxn = await fetch('/api/forward', {
      method: 'POST',
      body: JSON.stringify({ signature, data }),
      headers: {
        'Content-Type': 'application/json',
      },
    }).then((res) => res.json())

    if (metaTxn?.error) {
      throw new Error(`metaTxn error: ${metaTxn.error}`)
    }

    if (!metaTxn?.hash) {
      throw new Error(`metaTxn missing hash: ${metaTxn}`)
    }

    if (isMobile) {
      // Allow more Polygon calls happen at this point and increment the nonce...
      dispatchNonce(['update', { isFetching: false }])
    }

    showToast({
      command: command,
      hash: metaTxn.hash,
      error: metaTxn?.error,
    })

    addBreadcrumb({
      category: 'gawchi',
      message: `metaTxn hash:${metaTxn.hash} data:${JSON.stringify(data)} signature:${signature}`,
      level: Severity.Debug
    })

    // poll every second to find txn, but give up after a few failures
    try {
      const txn = await poll(async () => {
        const t = await polygonProvider.getTransaction(metaTxn.hash)
        if (!t) return undefined
        return t
      }, {
        retryLimit: 10,
        interval: 1000
      })

      addBreadcrumb({
        category: 'gawchi',
        message: `Txn fetched from Polygon hash:${txn.hash}`,
        level: Severity.Debug
      })

      log("Metatxn received & waiting for confirmation...", txn)
      let receipt = await txn.wait(1) // minimum # of confirmations

      completeToast({
        command: command,
        hash: metaTxn.hash
      })

      return receipt
    }
    catch (e: any) {
      failToast({
        command: command,
        hash: metaTxn.hash || e,
        error: e
      })

      // Still pass the error up
      throw e
    }
  }

  return {
    polygonForwarderSubmit
  }
}

export interface GawdTransactionReceipt extends TransactionReceipt {
  error?: Error | string
}

export interface TransactionError {
  error?: Error | string
}