import {
  GawdsContractFactory,
  ContractType, PolygonAddresses,
} from 'web/lib/contract'
import { TypedDataDomain, TypedDataField } from '@ethersproject/abstract-signer/lib'
import { Web3Provider } from '@ethersproject/providers'
import { BigNumberish, ethers } from 'ethers'
import { TrustedForwarder } from 'web/types/ethers-contracts'

export const getDataToSignForEIP712 = (request: TrustedForwarder.ForwardRequestStruct): TypedData => {
  const dataToSign: TypedData = {
    domain: {
      name: 'GAWDS',
      version: '0.0.1',
      verifyingContract: PolygonAddresses.TrustedForwarder
    },
    types: {
      ForwardRequest: [
        { name: 'from', type: 'address' },
        { name: 'to', type: 'address' },
        { name: 'value', type: 'uint256' },
        { name: 'gas', type: 'uint256' },
        { name: 'data', type: 'bytes' }
      ]
    },
    primaryType: 'ForwardRequest',
    message: request
  }
  return dataToSign
}

export function hashTypedData(typedData: TypedData): string {
  return ethers.utils._TypedDataEncoder.hash(typedData.domain, typedData.types, typedData.message);
}

export async function getSignature(data: TypedData, address: string, provider: Web3Provider): Promise<string> {
  if (!provider) {
    throw new Error('No provider set')
  }

  const signer = provider.getSigner(address)

  let signature: string
  signature = await signer._signTypedData(data.domain, data.types, data.message)
  return signature
}

export type TypedData = {
  domain: TypedDataDomain,
  types: Record<string, TypedDataField[]>,
  message: TrustedForwarder.ForwardRequestStruct,
  primaryType: string
}

export const getTypedDataForCommand = async (contractType: ContractType, command: string, args: any, account: string) => {
  const contract = GawdsContractFactory.get(contractType)

  const { data } = await contract.populateTransaction[command](...args)

  const gasLimit = await contract.estimateGas[command](...args, { from: account })
  const gasLimitNum = Number(gasLimit.toNumber().toString())

  const request: TrustedForwarder.ForwardRequestStruct = {
    from: account,
    to: contract.address,
    value: 0,
    gas: gasLimitNum,
    data,
  }

  return getDataToSignForEIP712(request)
}
