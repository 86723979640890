import { useContext } from 'react'
import { StoreContext, StoreAction, PolygonToastData } from 'web/lib/store'
import { PolygonTxnStatus } from 'web/components/TxnToast'

export default function usePolygonToast() {
  const {
    polygonTxns: [polygonTxns, updatePolygonTxns],
  } = useContext(StoreContext)

  const startToast = async (data: PolygonToastData) => {
    updatePolygonTxns([
      StoreAction.Add,
      {
        command: data.command,
        status: PolygonTxnStatus.Submitted,
        hash: data.hash,
        nonce: data.hash,
      },
    ])
  }

  const updateToastStatus = async (data: PolygonToastData, status: PolygonTxnStatus) => {
    updatePolygonTxns([
      StoreAction.Update,
      {
        command: data.command,
        status: status,
        hash: data.hash,
        nonce: data.hash,
      },
    ])
  }

  const failToast = (data: PolygonToastData) => {
    updateToastStatus(data, PolygonTxnStatus.Error)
  }

  const completeToast = (data: PolygonToastData) => {
    updateToastStatus(data, PolygonTxnStatus.Complete)
  }

  const showToast = async (data: PolygonToastData) => {
    startToast(data);

    // setTimeout(() => { completeToast(data) }, 15000);
  }

  return {
    showToast,
    completeToast,
    startToast,
    failToast
  }
}